<template>
  <v-menu
    v-if="canGenerate != null"
    offset-y
    :attach="true"
  >
    <template v-slot:activator="{ on }">
      <div
        class="d-flex flex-row align-center mb-2 clickable"
        v-on="on"
      >
        <v-btn
          icon
          small
        >
          <audit-bool-icon :value="canGenerate" />

        </v-btn>
        <span class="ml-2">
          {{$t('t.CanGenerateItems')}}
        </span>
      </div>
      <!--
      <v-list-item v-on="on">
        <v-list-item-avatar>
          <audit-bool-icon :value="canGenerate" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{$t('t.CanGenerateItems')}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
    </template>
    <v-card>
      <v-list>
        <v-list-item v-if="allItemsClosed != null">
          <v-list-item-avatar>
            <audit-bool-icon :value="allItemsClosed" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{$t('t.WaitForWorkItems')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="hasWaitEnoughForGeneration != null">
          <v-list-item-avatar>
            <audit-bool-icon :value="hasWaitEnoughForGeneration" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{$t('t.HasWaitEnoughForGeneration')}}</v-list-item-title>
            <v-list-item-subtitle>
              <span>{{$t('t.NumberOfDaysRemaining')}}</span>
              <span class="ml-2">{{hasWaitEnoughForGenerationDays}}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  components: {
    AuditBoolIcon: () => import('@/components/audit-bool-icon')
  },
  computed: {
    allItemsClosed () {
      return this.audit?.annotations?.allItemsClosed
    },
    canGenerate () {
      return this.auditLevel?.canGenerate
    },
    hasWaitEnoughForGeneration () {
      return this.auditLevel?.hasWaitEnoughForGeneration
    },
    hasWaitEnoughForGenerationDays () {
      return this.auditLevel?.hasWaitEnoughForGenerationDaysRemaining
    }
  },
  props: {
    audit: Object,
    auditLevel: Object
  }
}
</script>
